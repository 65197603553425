// 仪表盘
import { Request } from "@/http/request";

const api = {
  createForm: "/open/api-form/createForm",
  queryList: "/open/api-form/queryList",
  queryFormTemplate: "/open/api-form/queryFormTemplate",
  updateLineForm: "/open/api-form/updateLineForm",
  updateForm: "/open/api-form/updateForm",
  updateReuse: "/open/api-form/updateReuse",
  deleteForm: "/open/api-form/deleteForm",
  queryFormListByLineId: "/open/api-form/queryFormListByLineId",
  queryLineFormByLineId: "/open/api-form/queryLineFormByLineId",
  updateLineFormServiceMsg: "/open/api-form/updateLineFormServiceMsg",
}

/**
 * 创建表单
 * @param parameter {
 *  
    @ApiModelProperty("表单名称")
    private String formName;//必传

    //表单类型
    @ApiModelProperty("表单类型")
    private String formType;//必传

    //表单模板内容
    @ApiModelProperty("表单模板内容")
    private String formContext;//必传

    //是否必填 0false/1true
    @ApiModelProperty("是否必填")
    private Integer required;//必传

    //上传规则
    @ApiModelProperty("上传规则")
    private String uploadRule;//必传
 * }
 * @returns 
 */
export function createForm(parameter: any) {
  return Request.axiosInstance.post(api.createForm, parameter);
}
/**
 * 更新表单
 * @param parameter 更新表单data
 * @returns 
 */
export function updateForm(parameter: any) {
  return Request.axiosInstance.post(api.updateForm, parameter);
}
/**
 * 列表查询
 * @returns {
"code":200,
"message":"",
"data":[
{"id":1,"formName":"","formType":""},
{"id":2,"formName":"","formType":""},
]
}
 */
export function queryList() {
  return Request.axiosInstance.get(api.queryList);
}

/**
 * 详情
 * @returns 
 */
 export function queryFormTemplate(id:any) {
  return Request.axiosInstance.get(`${api.queryFormTemplate}?id=${id}`);
}

/**
 * 更新
请求参数：
{
    @ApiModelProperty("线路ID")
    private Long lineId;//必传

    @ApiModelProperty("选择的线路表单集合")
    private List<LineFormEntity> lineForms;//对象集合，[{"lineId":1,"formName":""},{"lineId":2,"formName":""}]
}

 * @returns 
返回参数
{
"code":200,
"message":"",
"data":{}
}
 */
export function updateLineForm(parameter:any) {
  return Request.axiosInstance.post(api.updateLineForm,parameter);
}


/**
 * 是否复用
 * @param parameter  Object {id:表单id,reuse:是否复用--1/0}
 * @returns Promise
 */
 export function updateReuse(parameter:any) {
  return Request.axiosInstance.post(api.updateReuse,parameter);
}

/**
 * 删除表单项
 * @param parameter  Object {id:表单id}
 * @returns Promise
 */
 export function deleteForm(parameter:any) {
  return Request.axiosInstance.post(api.deleteForm,parameter);
}

/**
 * 待勾选表单列表
 * @param parameter  Object {lineId:线路id}
 * @returns Promise
 */
 export function queryFormListByLineId(lineId:any) {
  return Request.axiosInstance.get(api.queryFormListByLineId+'?lineId='+lineId);
}
/**
 * 勾选表单列表
 * @param parameter  Object {lineId:线路id}
 * @returns Promise
 */
export function queryLineFormByLineId(lineId:any) {
  return Request.axiosInstance.get(api.queryLineFormByLineId+'?lineId='+lineId);
}

/**
 * 更新已选表单配置
 *  {id:3333,ranking=3,modelUrl:"",exampleUrl:"",description=""}
 *  Objectprivate Long id;
    private Integer ranking;
  
    //材料说明
    private String description;

    //示例文件地址
    private String exampleUrl;

    //模板文件地址
    private String modelUrl;
 * @returns Promise
 */
export function updateLineFormServiceMsg(parameter:any) {
  return Request.axiosInstance.post(api.updateLineFormServiceMsg,parameter);
}

export default {createForm,updateForm,queryList,queryFormTemplate,updateLineForm,updateReuse,deleteForm,queryFormListByLineId,queryLineFormByLineId,updateLineFormServiceMsg};
