<template>
  <div class="lineCard" v-loading="loading">
    <div class="flex-nowrap-space-between">
      <div class="flex-nowrap-flex-start lineCard-name">
        <icon-font :type="lineData.mode == '1'?'icon-huibomoshi':'icon-AXBmoshi'" class="icon-line-name" />
        <div class="line-name-font text-flow" style="width: 200px">
          {{ lineData.name }}
        </div>
      </div>
      <div class="flex-nowrap-flex-end">
        <div class="line-tag-box" :class="lineData.mode == '1'?'line-tag-box-1-color':'line-tag-box-0-color'">
          {{ modemaap[lineData.mode] }}</div>
      </div>
    </div>
    <div class="topright" @click="mtype = !mtype">{{mtype?'线路':'材料'}}
      <icon-font type="icon-a-lujing1281" />
    </div>
    <div class="flex-nowrap-space-between line-type" v-if="!mtype">
      <div class="flex-nowrap-flex-start" :class="status == 'ok'?'line-type-yes':'line-type-no'">
        <span class="line-type-name">线路状况：</span>
        <span class="line-type-point line-type-yes-bgcolor"></span>
        <span class="line-type-value line-type-yes-color">{{ lineData.status == 0?'正常':'停用' }}</span>
      </div>
      
      <div v-if="type == 'set'">
        <a-switch size="small" v-model:checked="lineData.status" :checkedValue="0" :unCheckedValue="1"
          @click="switchValue(lineData)" />
        <!-- <el-switch
                    v-model="lineData.status"
                    size="small" 
                    style="display: block"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :checkedValue="1"
                    :unCheckedValue="0"
                    @click="switchValue(lineData)"
                /> -->
      </div>
    </div>
    <div class="flex-nowrap-space-between line-type" v-if="!mtype">
      <div class="flex-nowrap-flex-start" :class="status == 'ok'?'line-type-yes':'line-type-no'" v-if="type == 'set'">
        <span class="line-type-name">申请退网：</span>
      </div>
      
      <div v-if="type == 'set'">
        <a-switch size="small" v-model:checked="lineData.amountOptions.numberRecyclesWitch" :checkedValue="'1'" :unCheckedValue="'0'"
          @click="switchValue1(lineData)" />
      </div>
    </div>
    
    <div class="flex-nowrap-space-between line-conter-box fixed-height" v-if="!mtype">
      <div class="line-item-name">资费</div>
      <div>
        <div class="flex-nowrap-flex-end" v-for="(sitem,s) in JSON.parse(lineData.options)" :key="s">
          <span class="line-item-blue">{{ sitem.final == ''?'0':sitem.final }}元/分钟</span>
          <span class="line-item-other">{{ pase(sitem.surcharge) }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="flex-nowrap-space-between line-conter-box"
         v-if="!mtype">
      <div class="line-item-name">小号库存</div>
      <div class="line-item-blue">
        {{ lineData.stock == null?'null':lineData.stock }}
      </div>
    </div> -->
    <div class="flex-nowrap-space-between line-conter-box" v-if="!mtype">
      <div class="line-item-name">主叫报备</div>
      <div>
        <div class="flex-nowrap-flex-end">
          <span class="line-item-blue">{{ lineData.report==0?'不需要':'需要'}}</span>
        </div>
      </div>
    </div>
    <div class="flex-nowrap-space-between line-conter-box"
         v-if="!mtype">
      <div class="line-item-name">主叫敏感地区</div>
      <el-tooltip :content="lineData.telAAreaIntercept == null?'***':lineData.telAAreaIntercept" placement="top-start" effect="light" popper-class="atooltip">
        <div class="textHiding">
          {{ lineData.telAAreaIntercept == null?'***':lineData.telAAreaIntercept }}
        </div>
      </el-tooltip>
    </div>
    <div class="flex-nowrap-space-between line-conter-box"
         v-if="!mtype">
      <div class="line-item-name">被叫敏感地区</div>
      <el-tooltip :content="lineData.telBAreaIntercept == null?'***':lineData.telBAreaIntercept" placement="top-start" effect="light" popper-class="atooltip">
        <div class="textHiding">
          {{ lineData.telBAreaIntercept == null?'***':lineData.telBAreaIntercept }}
        </div>
      </el-tooltip>
    </div>
    <div class="flex-nowrap-space-between line-conter-box"
         v-if="!mtype">
      <div class="line-item-name">小号月租(元)</div>
      <div class="line-item-blue">
        ￥{{ lineData.monthlyRent == null?'null':lineData.monthlyRent }}
      </div>
    </div>
    <div class="flex-nowrap-space-between line-conter-box" v-if="!mtype">
      <div class="line-item-name">并发限制：</div>
      <div class="line-item-blue">
        {{ lineData.concurren == null?'null':'1:'+lineData.concurren }}
      </div>
    </div>
    <div class="line-button-box flex-nowrap-space-between" v-if="!mtype">
      <div class="line-button-box-left">
        <slot name="left" :data='lineData'>
          <el-button size="medium" round>按钮1</el-button>
        </slot>
      </div>
      <div class="line-button-box-right">
        <slot name="right" :data='lineData'>
          <el-button size="medium" round>按钮2</el-button>
        </slot>
      </div>
    </div>
    <div class="list-context" v-else>
      <div class="flex-wrap" v-if="lineTag.length">
        <div class="list-item" v-for="(item,index) in lineTag" :key="index">{{item.formName}}</div>
      </div>
      <div v-else style="display:flex;justify-content:center;align-items:center;height:100%">
        暂无数据
      </div>
    </div>
    <div class="editbtn" v-if="mtype&&openInSetLine&&userType=='system'">
      <el-button class="edit" @click="chooseConfig">材料选择</el-button>
    </div>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import amount from '@/api/open/amount/amount'
import { queryLineFormByLineId } from '@/api/config/config'
export default {
  name: 'lineCard',
  components: {
    IconFont,
  },
  props: {
    lineData: {
      type: Object,
      default: {},
    },
    modemaap: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: 'set',
    },
    status: {
      type: String,
      default: 'ok',
    },
  },
  data() {
    return {
      value: '',
      mtype: false,
      lineTag: [],
      loading: false,
      userType: this.$store.getters.userType,
      openInSetLine: false,
    }
  },
  watch: {
    mtype(v) {
      // console.log(v);
      if (v) {
        this.getTagList()
      }
    },
  },
  mounted() {
    let str = this.$route.fullPath.toLowerCase()
    if (~str.indexOf('setline')) {
      this.openInSetLine = true
    }
  },
  methods: {
    getTagList() {
      this.loading = true
      queryLineFormByLineId(this.lineData.id)
        .then((res) => {
          this.lineTag = res.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    chooseConfig() {
      // console.log(this.lineData);
      this.$router.push({
        path: '/line/configListEdit',
        query: { id: this.lineData.id, name: escape(this.lineData.name) },
      })
    },
    pase(obj) {
      let value = ''
      obj.forEach((sobj, s) => {
        value = sobj.value + value
      })
      if (value == '') {
        return '(无录音)'
      } else {
        return '(含' + value + ')'
      }
    },
    switchValue(lineData) {
      amount
        .amountEdit({
          id: lineData.id,
          status: lineData.status,
        })
        .then((res) => {
          this.$notify({
            message: '服务商状态修改成功！',
            type: 'success',
          })
          this.$emit('addSuccess', 'ok')
        })
    },
    switchValue1(lineData){
      amount
        .setOption({
          amountId: lineData.id,
          name:'退网开关',
          optionKey:'numberRecyclesWitch',
          optionVal:Number(lineData.amountOptions.numberRecyclesWitch) ,
        })
        .then((res) => {
          if (res.code==200) {
             this.$notify({
            message: '申请退网修改成功！',
            type: 'success',
          })
          this.$emit('addSuccess', 'ok')
          }else{
             this.$notify({
            message: res.message||res.msg,
            type: 'error',
          })
          }
         
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.lineCard {
  width: 389px;
  // height: 400PX;
  padding: 20px;
  margin-bottom: 20px;
  // margin-left: 20px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 10px 0px rgba(132, 132, 132, 0.15);
  position: relative;
}
.icon-line-name {
  font-size: 20px;
  margin-right: 14px;
}
.line-name-font {
  font-size: 20px;
  font-weight: 700;
  color: #091241;
}
.line-tag-box {
  width: 70px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 12px;
  font-size: 12px;
  margin-right: 14px;
}
.line-tag-box-0-color {
  background: #d8e5ff;
  color: #0058ff;
}
.line-tag-box-1-color {
  background: #fff6d8;
  color: #ff6600;
}
.line-type {
  padding: 25px 35px;
  height: 30px;
}
.line-type-name {
  font-size: 12px;
  font-weight: 700;
  color: #091241;
}
.line-type-point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 4px;
}
.line-type-value {
  font-size: 12px;
  font-weight: 700;
}
.line-type-yes {
  .line-type-yes-color {
    color: #15db15;
  }
  .line-type-yes-bgcolor {
    background: #15db15;
  }
}
.line-type-no {
  .line-type-yes-color {
    color: #ff4747;
  }
  .line-type-yes-bgcolor {
    background: #ff4747;
  }
}
.line-conter-box {
  margin: 3px 0;
  padding: 6px 18px;
  background: #f9f9f9;
  border-radius: 4px;
  .line-item-name {
    font-size: 12px;
    font-weight: 700;
    color: #999999;
  }
  .line-item-blue {
    font-size: 12px;
    font-weight: 700;
    color: #637dff;
  }
  .line-item-other {
    font-size: 12px;
    font-weight: 700;
    color: #091241;
  }

  .textHiding {
    font-size: 12px;
    font-weight: 700;
    color: #637dff;
    width: 120px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.line-button-box {
  height: 70px;
  .line-button-box-left {
    width: 38%;
    button {
      width: 100%;
      color: #333333;
      background: #f2f3f8;
    }
  }
  .line-button-box-right {
    width: 58%;
    button {
      width: 100%;
      color: #ffffff;
      background: #637dff;
    }
  }
}
.fixed-height {
  height: 62px;
}
.topright {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
  padding: 5px 10px;
  // border-radius: 2000px 0 0 2000px;
  background: #ebeeff;
  color: #637dff;
  cursor: pointer;
  user-select: none;
}
.list-context {
  height: 285px;
  overflow: auto;
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .list-item {
      background: rgba(148, 162, 201, 0.07);
      padding: 5px;
      margin: 5px;
      color: #233975;
      display: block;
      user-select: none;
      font-size: 12px;
    }
  }
}
.editbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  .edit.el-button {
    margin: auto;
    width: 340px;
    height: 35px;
    background: #637dff;
    border-radius: 18px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>